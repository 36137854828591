import React from 'react'
import './insideheader.css'
import ReactMarkdown from 'react-markdown'

const SingleColumn = ({ itemHeading }) => (


  <header className="section-header">
    <ReactMarkdown source={itemHeading} escapeHtml={false} />
    <hr />
  </header>





)

export default SingleColumn
