import React from 'react'
import './insideheader.css'
import ReactMarkdown from 'react-markdown'
import { Link } from 'gatsby'


const IconSocial = ({ itemHeading, itemContent, itemImage }) => (
    <p style={{display: "inline"}}>
    <a className="fs-12 fw-600" target="_blank" href={itemContent}><span className="iconbox text-white bg-dark"><i className={itemImage}></i></span></a>
    &nbsp;
  </p>


)

export default IconSocial
